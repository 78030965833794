
import React from "react";
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DeleteAccountModal from './Modal/DeleteAccountModal';
import EditAccountModal from './Modal/EditAccountModal';
import AddAccountModal from "./Modal/AddAccountModal";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './SidePanel.css';
import { DropdownItem } from "react-bootstrap";
import serverUrl from "constants";
import AddFriendModal from "./Modal/AddFriendModal";
import FriendRequestsModal from "./Modal/FriendRequestsModal";
import SeeFriendsModal from "./Modal/SeeFriendsModal";

const SidePanel = ({ activePage, onChange }) => {
    let listItemClass = "nav-link flex-grow align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none";
    let activeListItemClass = "nav-link active flex-grow align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none";
    const [currentAccountId, setCurrentAccountId] = useState("");

    const [accounts, setAccounts] = useState([]);
    const getUserAccounts = async () => {
        // Get accounts
        const accountsData = await fetch(serverUrl + "accounts.php");
        const accountsRes = await accountsData.json();
        setAccounts(accountsRes?.data);

        // Set current account
        const curAccData = await fetch(serverUrl + "current_account.php");
        const curAccRes = await curAccData.json();
        const hasAccounts = accounts != null && accounts.length > 0;

        if (accountsRes.status != "ERROR") {
            setCurrentAccountId(curAccRes?.data == null && hasAccounts ? accountsRes?.data[0]?.id
                : curAccRes?.data == null ? "Create an account"
                    : accountsRes?.data.find(a => a.id == curAccRes?.data)?.id);
        } else setCurrentAccountId("Create an account");

        if (curAccRes.data != null && onChange)
            onChange(accountsRes?.data?.find(a => a.id == curAccRes.data));
    };

    const [friendRequests, setFriendRequests] = useState({ sent: [], received: [] });
    const getFriendRequests = async () => {
        const requestsData = await axios.get(serverUrl + "requests.php");
        const requestsRes = await requestsData;
        setFriendRequests({ sent: requestsRes.data.sentRequests, received: requestsRes.data.receivedRequests });
    };

    const [friends, setFriends] = useState([]);
    const getFriends = async () => {
        const friendsData = await axios.get(serverUrl + "friends.php");
        const friendsRes = await friendsData;
        if (friendsRes?.data?.data)
            setFriends(friendsRes?.data?.data);
        else
            setFriends([])
    };

    useEffect(() => {
        getUserAccounts();
        getFriendRequests();
        getFriends();
    }, []);

    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const handleCloseDelete = () => {
        setShowDeleteAccount(false);
        getUserAccounts();
    };
    const handleShowDelete = () => setShowDeleteAccount(true);

    const [showEditAccount, setShowEditAccount] = useState(false);
    const handleCloseEdit = () => {
        setShowEditAccount(false);
        getUserAccounts();
    };
    const handleShowEdit = () => setShowEditAccount(true);

    const [showAddAccount, setShowAddAccount] = useState(false);
    const handleCloseAdd = () => {
        setShowAddAccount(false);
        getUserAccounts();
    };
    const handleShowAdd = () => setShowAddAccount(true);

    const [showAddFriend, setShowAddFriend] = useState(false);
    const handleCloseAddFriend = () => {
        setShowAddFriend(false);
        getFriendRequests();
    };
    const handleAddFriend = () => setShowAddFriend(true);

    const [showSeeFriends, setShowSeeFriends] = useState(false);
    const handleCloseSeeFriends = () => {
        setShowSeeFriends(false);
        getFriends();
    };
    const handleSeeFriends = () => setShowSeeFriends(true);

    const [showFriendRequests, setShowFriendRequests] = useState(false);
    const handleCloseFriendRequests = () => {
        setShowFriendRequests(false);
        getFriendRequests();
        getFriends();
    };
    const handleSeeFriendRequests = () => setShowFriendRequests(true);

    const navigate = useNavigate();
    const onAccountChange = async (e) => {
        const accountId = e.currentTarget.dataset.id;
        setCurrentAccountId(accountId);
        const param = { id: accountId };

        await axios.post(serverUrl + "current_account.php", param);
        onChange(accounts?.find(a => a.id == accountId));
    };

    const handleLogout = async (e) => {
        e.preventDefault();

        await axios.post(serverUrl + "logout.php");
        navigate("/login");
    };
    const hasAnAccount = currentAccountId != "Create an account";
    const requestCount = friendRequests?.sent?.length + friendRequests?.received?.length || 0;
    return (
        <div>
            <svg className="d-none">
                <symbol id="people-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </symbol>
                <symbol id="shield" viewBox="0 0 16 16">
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547  -.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887  .87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                </symbol>
                <symbol id="calculator" viewBox="0 0 16 16">
                    <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1   .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5  .5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
                </symbol>
                <symbol id="list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l  -.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146  .147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                </symbol>
                <symbol id="dollar" viewBox="0 0 16 16">
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454  .966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                </symbol>
                <symbol id="logout" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                    <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </symbol>
                <symbol id="heart" viewBox="0 0 16 16">
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                </symbol>
            </svg>

            <aside className="d-flex flex-nowrap desktop-sidebar">
                <div className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark sidepanel">
                    <span className="fs-4">Manage</span>
                    <hr />
                    <ul className="nav nav-pills flex-column mb-auto">
                        <li className="nav-item">
                            <Link className={activePage == "Knights" ? activeListItemClass : listItemClass}
                                to="/knights">
                                <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#shield" /></svg>
                                Knights
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={activePage == "Calculator" ? activeListItemClass : listItemClass}
                                to="/calculator">
                                <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#calculator" /></svg>
                                State Power calculator
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={activePage == "LoverCalculator" ? activeListItemClass : listItemClass}
                                to="/lovercalculator">
                                <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#heart" /></svg>
                                Lover calculator
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={activePage == "Resources" ? activeListItemClass : listItemClass}
                                to="/resources">
                                <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#dollar" /></svg>
                                Resources
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={activePage == "Todos" ? activeListItemClass : listItemClass}
                                to="/todos">
                                <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#list" /></svg>
                                To-Do list
                            </Link>
                        </li>
                    </ul>
                    <hr />
                    <Dropdown data-bs-theme="dark">
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="dark">
                            <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#people-circle" /></svg>
                            <strong>{accounts?.find(a => a.id == currentAccountId)?.name ?? currentAccountId}</strong>
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            {accounts?.map((account, idx) => (
                                <DropdownItem
                                    eventKey={idx}
                                    key={account.id}
                                    style={account.id == currentAccountId ? { color: "gray" } : {}}
                                    data-name={account.name}
                                    data-id={account.id}
                                    onClick={onAccountChange}>
                                    {account.name}&nbsp;[{account.server}]
                                </DropdownItem>
                            ))}
                            {hasAnAccount && <Dropdown.Divider />}
                            {hasAnAccount && <Dropdown.Item eventKey="4" onClick={handleShowDelete}>Delete current account</Dropdown.Item>}
                            {hasAnAccount && <Dropdown.Item eventKey="5" onClick={handleShowEdit}>Edit current account</Dropdown.Item>}
                            <Dropdown.Item eventKey="6" onClick={handleShowAdd}>Add new account</Dropdown.Item>
                            <Dropdown.Divider />
                            {friends.length > 0 && <Dropdown.Item eventKey="9" onClick={handleSeeFriends}>See friends</Dropdown.Item>}
                            {hasAnAccount && <Dropdown.Item eventKey="8" onClick={handleAddFriend}>Add friend</Dropdown.Item>}
                            {requestCount > 0 && <Dropdown.Item eventKey="10" onClick={handleSeeFriendRequests}>See friend requests ({requestCount})</Dropdown.Item>}
                            {hasAnAccount && <Dropdown.Divider />}
                            <Dropdown.Item eventKey="7" onClick={handleLogout}>
                                Sign out
                                <svg className="bi pe-none me-2" width="16" height="16" style={{ marginLeft: "10px" }}><use xlinkHref="#logout" /></svg>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </aside>
            <DeleteAccountModal show={showDeleteAccount} handleClose={handleCloseDelete} />
            <EditAccountModal show={showEditAccount} handleClose={handleCloseEdit} />
            <AddAccountModal show={showAddAccount} handleClose={handleCloseAdd} />
            <AddFriendModal show={showAddFriend} handleClose={handleCloseAddFriend} />
            {friends.length > 0 && <SeeFriendsModal show={showSeeFriends} friends={friends} accounts={accounts} handleClose={handleCloseSeeFriends} />}
            {friendRequests && <FriendRequestsModal show={showFriendRequests} requests={friendRequests} handleClose={handleCloseFriendRequests} />}
        </div>
    );
};

SidePanel.propTypes = {
    activePage: PropTypes.string,
    onChange: PropTypes.func
}

export default SidePanel;
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import serverUrl from "constants";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const FriendAccessModal = ({ show, friendUID, friendName, accounts, handleClose }) => {
    const [formValue, setFormValue] = useState({ type: "access", friendUID: friendUID, permissions: "" });
    const [accountAccesses, setAccountAccesses] = useState(accounts?.map(a => { return { id: a.id, isAccessible: false } }));

    const getPermissions = async () => {

        const params = { friendUID: friendUID };
        const permissionsData = await axios.get(serverUrl + "permissions.php", { params: params });
        const permissionsRes = await permissionsData;
        if (permissionsRes?.data?.data) {
            const permissions = permissionsRes?.data?.data;
            const accesses = accountAccesses?.map(access => {
                if (permissions.some(p => p.accountID === access.id)) {
                    return {
                        ...access,
                        isAccessible: true,
                    };
                } else {
                    return access;
                }
            });
            setAccountAccesses(accesses);
        }
    };

    useEffect(() => {
        getPermissions();
    }, [friendUID, show]);

    const handleAccountChange = async (e) => {
        const accountId = e.currentTarget.id;
        const accesses = accountAccesses?.map(access => {
            if (access.id === accountId) {
                return {
                    ...access,
                    isAccessible: !access.isAccessible,
                };
            } else {
                return access;
            }
        });
        setAccountAccesses(accesses);

        const accessibleAccounts = accesses.filter(a => a.isAccessible).map(access => access.id);
        setFormValue({ ...formValue, permissions: JSON.stringify(accessibleAccounts) })
    };

    const handlePermissionRequest = async () => {
        formValue.friendUID = friendUID;
        await axios.put(serverUrl + "friends.php", formValue);
        handleClose();
    };

    return (
        <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Which accounts can {friendName} see?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {accounts?.map((account) =>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id={account.id}
                            key={account.id}
                            checked={accountAccesses.find(access => access.id === account.id)?.isAccessible}
                            label={account.name}
                            onChange={handleAccountChange}
                        />)}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handlePermissionRequest}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

FriendAccessModal.propTypes = {
    show: PropTypes.bool,
    friendUID: PropTypes.string,
    friendName: PropTypes.string,
    accounts: PropTypes.array,
    handleClose: PropTypes.func
}

export default FriendAccessModal;
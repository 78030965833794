import React from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";
import { Tab, Tabs } from "react-bootstrap";

const FriendRequestsModal = ({ show, requests, handleClose }) => {
    const handleAcceptRequest = async (e) => {
        //e.preventDefault();

        const formData = { friendUID: e };
        await axios.put(serverUrl + "requests.php", formData);
        handleClose();
    };

    const handleRejectRequest = async (e) => {
        //e.preventDefault();

        const formData = { friendUID: e, isCancel: 0 };
        await axios.delete(serverUrl + "requests.php", { data: formData });
        handleClose();
    };

    const handleCancelRequest = async (e) => {
        //e.preventDefault();
        const formData = { friendUID: e, isCancel: 1 };
        await axios.delete(serverUrl + "requests.php", { data: formData });
        handleClose();
    };


    return (
        <>
            <svg className="d-none">
                <symbol id="accept" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                </symbol>
                <symbol id="reject" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </symbol>
            </svg>
            <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Friend requests</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Tabs
                            defaultActiveKey="received"
                            id="receivedRequests"
                            className="mb-3"
                            fill
                            variant="underline">
                            <Tab eventKey="received" title="Received">
                                {requests.received?.length == 0 && <p>{"No requests :("}</p>}
                                {requests.received?.length > 0 && requests.received.map((request) =>
                                    <div className="see-friends-item" key={request.mainUID}>
                                        <div>From: {request.mainName}</div>
                                        <div className="see-friends-buttons">

                                            <button className="btn btn-icon btn-outline-success ml-025" onClick={() => handleAcceptRequest(request.mainUID)} title="Accept">
                                                <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#accept" /></svg>
                                            </button>
                                            <button className="btn btn-icon btn-outline-danger ml-025" onClick={() => handleRejectRequest(request.mainUID)} title="Reject">
                                                <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#reject" /></svg>
                                            </button>
                                        </div>
                                    </div>)}
                            </Tab>
                            <Tab eventKey="sent" title="Sent">
                                {requests.sent?.length == 0 && <p>{"No requests :("}</p>}
                                {requests.sent?.length > 0 && requests.sent.map((request) =>
                                    <div className="see-friends-item" key={request.friendUID}>
                                        <div>To: {request.friendName}</div>
                                        <div className="see-friends-buttons">

                                            <button className="btn btn-icon btn-outline-secondary ml-025" onClick={() => handleCancelRequest(request.friendUID)} title="Cancel">
                                                <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#reject" /></svg>
                                            </button>
                                        </div>
                                    </div>)}
                            </Tab>
                        </Tabs>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    );
};

FriendRequestsModal.propTypes = {
    show: PropTypes.bool,
    requests: PropTypes.object,
    handleClose: PropTypes.func
}

export default FriendRequestsModal;
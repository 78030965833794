import React, { useState } from "react";
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";
import RenameFriendModal from "./RenameFriendModal";
import FriendAccessModal from "./FriendAccessModal";
import { useNavigate } from "react-router-dom";

const SeeFriendsModal = ({ show, friends, accounts, handleClose }) => {
    const [editingUID, setEditingUID] = useState("");

    const handleDeleteRequest = async (uid) => {
        const formData = { friendUID: uid };
        await axios.delete(serverUrl + "friends.php", { data: formData });
        handleClose();
    };

    const [showRenameFriend, setShowRenameFriend] = useState(false);
    const handleCloseRename = () => {
        setShowRenameFriend(false);
        handleClose();
    };
    const handleShowRename = (friendUid) => {
        setEditingUID(friendUid);
        setShowRenameFriend(true);
    }

    const [showFriendAccess, setShowFriendAccess] = useState(false);
    const handleCloseAccess = () => {
        setShowFriendAccess(false);
        handleClose();
    };
    const handleShowAccess = (friendUid) => {
        setEditingUID(friendUid);
        setShowFriendAccess(true);
    }
    const navigate = useNavigate();
    const navigateToVisit = (id) => {
        navigate("/knights/visit", {
            state: { id }
        });
    }

    return (
        <>
            <svg className="d-none">
                <symbol id="edit" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </symbol>
                <symbol id="delete" viewBox="0 0 16 16">
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                </symbol>
                <symbol id="visit" viewBox="0 0 16 16">
                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                </symbol>
                <symbol id="settings" viewBox="0 0 16 16">
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </symbol>
            </svg>
            <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Friends</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {friends?.map((friend) =>
                        <div className="see-friends-item" key={friend?.friendUID}>
                            <div>{friend?.friendName}</div>
                            <div className="see-friends-buttons">

                                <button className="btn btn-icon btn-outline-success ml-025" onClick={() => navigateToVisit(friend?.friendUID)} title="Visit">
                                    <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#visit" /></svg>
                                </button>
                                <button className="btn btn-icon btn-outline-secondary ml-025" onClick={() => handleShowRename(friend?.friendUID)} title="Edit name">
                                    <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#edit" /></svg>
                                </button>
                                <button className="btn btn-icon btn-outline-secondary ml-025" onClick={() => handleShowAccess(friend?.friendUID)} title="Manage permissions">
                                    <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#settings" /></svg>
                                </button>
                                <button className="btn btn-icon btn-outline-danger ml-025" onClick={() => handleDeleteRequest(friend?.friendUID)} title="Remove">
                                    <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#delete" /></svg>
                                </button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal >
            <RenameFriendModal show={showRenameFriend} friendUID={editingUID} handleClose={handleCloseRename} />
            {showFriendAccess && <FriendAccessModal show={showFriendAccess} friendUID={editingUID} friendName={friends.find(f => f.friendUID == editingUID)?.friendName} accounts={accounts} handleClose={handleCloseAccess} />}
        </>
    );
};

SeeFriendsModal.propTypes = {
    show: PropTypes.bool,
    friends: PropTypes.array,
    accounts: PropTypes.array,
    handleClose: PropTypes.func
}

export default SeeFriendsModal;
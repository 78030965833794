import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import serverUrl from "constants";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const RenameFriendModal = ({ show, friendUID, handleClose }) => {
    const [formValue, setFormValue] = useState({ type: "rename", friendUID: friendUID, friendName: '' });
    const [validated, setValidated] = useState(false);

    const handleInput = (e) => {
        setValidated(false);
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const handleRenameRequest = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        formValue.friendUID = friendUID;
        if (formValue.friendName) {
            await axios.put(serverUrl + "friends.php", formValue);
            handleClose();
        }
    };

    return (
        <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Friends</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    <Form.Group controlId="editForm.friendName">
                        <Form.Control
                            required
                            placeholder="New friend's name"
                            name="friendName"
                            onChange={handleInput}
                            value={formValue.friendName}
                            autoFocus
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleRenameRequest}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

RenameFriendModal.propTypes = {
    show: PropTypes.bool,
    friendUID: PropTypes.string,
    handleClose: PropTypes.func
}

export default RenameFriendModal;
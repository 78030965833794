import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";

const AddFriendModal = ({ show, handleClose }) => {
    const [formValue, setFormValue] = useState({ email: '', friendName: '', userName: '' });

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const handleAdd = async (e) => {
        e.preventDefault();

        const formData = { email: formValue.email, friendName: formValue.friendName, userName: formValue.userName };
        await axios.post(serverUrl + "requests.php", formData);
        handleClose();
    };

    return (
        <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Make friends</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="addForm.email">
                        <Form.Control
                            placeholder="Friend's KC Planner email"
                            name="email"
                            onChange={handleInput}
                            value={formValue.email}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addForm.friendname">
                        <Form.Control
                            placeholder="Friend's name"
                            name="friendName"
                            onChange={handleInput}
                            value={formValue.friendName}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="addForm.username">
                        <Form.Control
                            placeholder="Your name to them"
                            name="userName"
                            onChange={handleInput}
                            value={formValue.userName}
                            autoFocus
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAdd}>
                    Send request
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddFriendModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
}

export default AddFriendModal;
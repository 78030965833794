import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import './SidePanel.css';
import { DropdownItem } from "react-bootstrap";

const VisitorSidePanel = ({ accounts, onChange }) => {
    const [currentAccountId, setCurrentAccountId] = useState(accounts?.length > 0 ? accounts[0].id : "No account");

    const navigate = useNavigate();
    const onAccountChange = async (account) => {
        setCurrentAccountId(account.id);
        onChange(account);
    };

    useEffect(() => {
        setCurrentAccountId(accounts?.length > 0 ? accounts[0].id : "No account");
    }, [accounts]);

    const handleReturn = async (e) => {
        e.preventDefault();
        navigate("/knights");
    };
    const hasAnAccount = accounts?.length > 0;
    return (
        <div>
            <svg className="d-none">
                <symbol id="people-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </symbol>
                <symbol id="logout" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                    <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </symbol>
            </svg>

            <aside className="d-flex flex-nowrap desktop-sidebar">
                <div className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark sidepanel">
                    <span className="fs-4">Visit</span>
                    <hr />
                    <Dropdown data-bs-theme="dark">
                        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="dark">
                            <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#people-circle" /></svg>
                            <strong>{accounts?.find(a => a.id == currentAccountId)?.name ?? currentAccountId}</strong>
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            {accounts?.map((account, idx) => (
                                <DropdownItem
                                    eventKey={idx}
                                    key={account.id}
                                    style={account.id == currentAccountId ? { color: "gray" } : {}}
                                    data-name={account.name}
                                    data-id={account.id}
                                    onClick={() => onAccountChange(account)}>
                                    {account.name}&nbsp;[{account.server}]
                                </DropdownItem>
                            ))}
                            {hasAnAccount && <Dropdown.Divider />}
                            <Dropdown.Item eventKey="7" onClick={handleReturn}>
                                Return home
                                <svg className="bi pe-none me-2" width="16" height="16" style={{ marginLeft: "10px" }}><use xlinkHref="#logout" /></svg>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </aside>
        </div>
    );
};

VisitorSidePanel.propTypes = {
    accounts: PropTypes.array,
    onChange: PropTypes.func
}

export default VisitorSidePanel;